<template>
<b-row>
  <b-col>
    <b-button-group v-b-hover="handleHover" vertical>
      <b-button size="sm" v-if="modified === true"
        :variant="isHovered ? 'success' : 'primary'" 
        class="mb-2"
        v-b-tooltip.hover.right title="Approve Update"
        @click="$emit('update')"
      >
        <b-icon-check-circle-fill aria-label="Update"></b-icon-check-circle-fill>
      </b-button>
     <b-button size="sm" v-if="modified === true"
       :variant="isHovered ? 'warning' : 'primary'"
       class="mb-2"
       v-b-tooltip.hover.right title="Undo Changes"
       @click="$emit('undo')"
      >
        <b-icon-arrow-clockwise aria-label="Undo"></b-icon-arrow-clockwise>
      </b-button>
      <b-button size="sm" v-if="modified === false"
        :variant="isHovered ? 'danger' : 'primary'"
        class="mb-2"
        v-b-tooltip.hover.right title="Delete"
        @click="$emit('delete')"
      >
        <b-icon-dash-circle-fill> aria-label="Delete"></b-icon-dash-circle-fill>
      </b-button>
      <b-button size="sm" v-if="modified === false"
        variant="primary"
        class="mb-2"
        v-b-tooltip.hover.right title="Cancel / Dismiss"
        @click="$emit('cancel')"
      >
        <b-icon-x-circle-fill>aria-label="Cancel"></b-icon-x-circle-fill>
      </b-button>
      <b-button size="sm"
        variant="primary"
        class="mb-2"
        v-b-tooltip.hover.right title="Help"
        @click="$emit('help')"
      >
        <b-icon-question-circle-fill> aria-label="Help"></b-icon-question-circle-fill>
      </b-button>
    </b-button-group>
  </b-col>
  <!-- <b-col v-if="false">
    <div v-b-hover="handleHover" class="border rounded py-3 px-4">
      <b-icon-battery v-if="isHovered" scale="2"></b-icon-battery>
      <b-icon-battery-full v-else scale="2"></b-icon-battery-full>
      <span class="ml-2" :class="isHovered ? 'text-danger' : ''">Hover this area</span>
    </div>
  </b-col> -->
</b-row>
</template>

<script>
import {
  BIconCheckCircleFill,
  BIconXCircleFill,
  BIconDashCircleFill,
  BIconQuestionCircleFill,
  BIconArrowClockwise
} from 'bootstrap-vue'
export default {
  components: {
    BIconCheckCircleFill,
    BIconXCircleFill,
    BIconDashCircleFill,
    BIconQuestionCircleFill,
    BIconArrowClockwise
  },
  props: {
    modified : {
      Type: Boolean
    }
  },
  data() {
    return {
      isHovered: false
    }
  },
  methods: {
    handleHover(hovered) {
      this.isHovered = hovered
    }
  }
}
</script>
